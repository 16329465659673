import { RestaurantDataWithDistance } from "@src/app/types/foodMapTypes";

export default function SortRestaurantsOnDistance(
  restaurantData: RestaurantDataWithDistance[],
) {
  const sortedData = [...restaurantData];
  sortedData.sort((a, b) => a.distance - b.distance);
  const filteredData = sortedData.filter(
    (resturant) => resturant.distance < 5 && resturant.distance >= 0,
  );
  return filteredData;
}
