/**
 * Company: SPHMedia
 * Description: This is the base page component for all BH pages
 */

import CASBlock from "@components/CASBlock/CASBlock";
import { MainContainerNew } from "@components/Container/MainContainerNew";
import CatfishAd from "@components/Elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import PrestitialAd from "@components/Elements/Advertisement/PrestitialAd";
import { Footer } from "@components/Footer/Footer";
import { Header } from "@components/Header/Header";
import { HeaderNew } from "@components/HeaderNew/HeaderNew";
import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import MetaTags from "@elements/MetaTags/MetaTags";
import { CAS_EI_MODEL_ID } from "@helper/getEnvVariables";
import { getFeatureFlag } from "@helper/utils";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import { MainContainer } from "@src/app/components/Container/MainContainer";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { AdTargetingType, PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement, useEffect, useState } from "react";

export type BHBasePageProps = {
  uniqueSectionName?: string;
  children?: React.ReactNode;
  hasLb1Ads?: boolean;
  gaData?: GADataProps;
  metaTags?: MetaTagsProps;
  pageAdTargetValue: PageAdTargetValue;
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function BHBasePage({
  uniqueSectionName,
  children,
  hasLb1Ads = true,
  gaData,
  metaTags,
  pageAdTargetValue,
}: BHBasePageProps): ReactElement {
  const {
    isPrestitialEnabled,
    isCatfishEnabled,
    topOverlayImpressions,
    topOverlayValidity,
  } = useGlobalAdSetting();
  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  const [showNewMasthead, setShowNewMasthead] = useState<boolean>();

  useEffect(() => {
    const get_new_masthead_feature_flag = async () => {
      const new_masthead_flag: string = await getFeatureFlag(
        FeatureFlags.NEW_MASTHEAD,
      );
      if (new_masthead_flag === "1") {
        setShowNewMasthead(true);
      } else {
        setShowNewMasthead(false);
      }
    };
    void get_new_masthead_feature_flag();
  }, []);

  const pageAdTarget = pubPageAdTargeting[pageAdTargetValue] as AdTargetingType<
    string,
    PageAdTargetValue
  >;

  return (
    <>
      {gaData && <GAData {...gaData} />}
      {metaTags && <MetaTags {...metaTags} />}
      {showNewMasthead ? (
        <MainContainerNew>
          <HeaderNew />
          <div className="max-w-screen-xl w-full px-sm mx-auto">
            {hasLb1Ads && (
              <AdsLb1
                uniqueSectionName={uniqueSectionName}
                pageAdTargetType={pageAdTargetValue}
              />
            )}
            <PrestitialAd
              slot={GoogleAdsSlotFactory.prestitial(uniqueSectionName)}
              isPrestitialEnabled={isPrestitialEnabled}
              pubAdTargeting={[pageAdTarget]}
              topOverlayImpressions={topOverlayImpressions}
              topOverlayValidity={topOverlayValidity}
            />
            {children}
          </div>
        </MainContainerNew>
      ) : (
        <MainContainer>
          <Header />
          <div className="w-full px-sm">
            {hasLb1Ads && (
              <AdsLb1
                uniqueSectionName={uniqueSectionName}
                pageAdTargetType={pageAdTargetValue}
              />
            )}
            <PrestitialAd
              slot={GoogleAdsSlotFactory.prestitial(uniqueSectionName)}
              isPrestitialEnabled={isPrestitialEnabled}
              pubAdTargeting={[pageAdTarget]}
              topOverlayImpressions={topOverlayImpressions}
              topOverlayValidity={topOverlayValidity}
            />
            {children}
          </div>
        </MainContainer>
      )}
      <Footer />
      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(uniqueSectionName)}
        isCatfishEnabled={isCatfishEnabled}
        pubAdTargeting={[pageAdTarget]}
      />
      <CASBlock casId={CAS_EI_MODEL_ID} />
    </>
  );
}
