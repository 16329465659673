import SPHLogoMobile from "@assets/BHLogoMobile.svg";
import closeIcon from "@assets/header/menu/menuCloseNew.svg";
import styles from "@components/HeaderNew/Header.module.css";
import {
  MOBILE_MAIN_LIST,
  MOBILE_MISC_LIST,
  MOBILE_SECONDARY_LISTING,
  MOBILE_SECTION_LIST,
} from "@components/HeaderNew/headerMenuConstants";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import { getFeatureFlag } from "@src/app/helper/utils";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";

import { HeaderMenuList } from "./HeaderMenuList";
import MySphLoginButton from "./MySphLoginButton";
import { SearchInput } from "./Search/SearchInput";
import UserInfo from "./UserInfo/UserInfo";

export type MobileMenuModalProps = {
  expandMenu: boolean;
  onClick: () => void;
};

export function MobileMenuModal({
  expandMenu,
  onClick,
}: MobileMenuModalProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const [mainList, setMainList] = useState(MOBILE_MAIN_LIST);
  const [sectionList, setSectionList] = useState(MOBILE_SECTION_LIST);

  useEffect(() => {
    const filter_header = async () => {
      const genG_Flags: string = await getFeatureFlag(FeatureFlags.GEN_G);
      const webinar: string = await getFeatureFlag(FeatureFlags.WEBINAR);
      setMainList(
        MOBILE_MAIN_LIST.filter((item) => {
          if (item.title === "Webinar" && webinar === "1") {
            return true;
          }
          return item.title !== "Webinar";
        }),
      );
      setSectionList(
        MOBILE_SECTION_LIST.filter((item) => {
          if (item.title === "Gen-G" && genG_Flags === "1") {
            return true;
          }
          return item.title !== "Gen-G";
        }),
      );
    };
    void filter_header();
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 overflow-x-hidden w-full h-full bg-black-800 text-white-500 text-sm font-semimedium z-[999] transform transition-all duration-500 ease-in-out
            ${
              expandMenu
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            }`}
    >
      <div className="sticky top-0 bg-black-800 z-50">
        <div className="flex items-center justify-between h-[40px] px-xs">
          <div className="lg:hidden">
            <img
              src={SPHLogoMobile}
              alt="SPH Logo mobile"
              width={132}
              height={25}
              className="lg:hidden"
            />
          </div>
          <button onClick={onClick}>
            <img src={closeIcon} alt="menu" width={24} height={24} />
          </button>
        </div>
        <div>
          <SearchInput expanded={true} isMobile forModal />
        </div>
      </div>
      <div>
        {userDisplayName && (
          <div className="flex justify-start items-center">
            <UserInfo
              rootClassName="whitespace-nowrap w-full"
              userInfo={
                {
                  ...userInfo,
                  lastname: userDisplayName,
                  loginId: OKTAUserInfo.loginid,
                  sub: OKTAUserInfo.reguserstatus,
                } as UserInfoType
              }
            />
          </div>
        )}
        <div
          className={cx(
            styles.mobileUserSection,
            "flex items-center h-[54px] gap-xl text-sm border-white-600 border-b-[1px] border-opacity-25 px-sm",
            !userDisplayName && "justify-center",
            userDisplayName && "justify-start",
          )}
        >
          <a
            href="https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-header"
            className=""
          >
            Langgan
          </a>
          {!userDisplayName && (
            <>
              <span className="text-white-100 opacity-25">|</span>
              <MySphLoginButton
                buttonStyle="gap-2"
                iconWidth="20px"
                iconHeight="19px"
                textOnly
              />
            </>
          )}
        </div>
        <div className="mt-sm mb-sm px-sm">
          <HeaderMenuList
            testId="mobile-misc-links"
            items={MOBILE_MISC_LIST}
            type="mobileMisc"
          />
          <HeaderMenuList
            testId="mobile-main-links"
            items={sectionList}
            type="mobileSection"
          />
          <HeaderMenuList
            testId="mobile-main-links"
            items={MOBILE_SECONDARY_LISTING}
            type="mobileSecondary"
          />
          <HeaderMenuList
            testId="mobile-main-links"
            items={mainList}
            type="mobileMain"
          />
        </div>
      </div>
    </div>
  );
}
