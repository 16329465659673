import { stripeAdsBackground } from "@components/Elements/Advertisement/Advertisement";
import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";

import { pubPageAdTargeting } from "../AdsConstants";

type AdsLbProps = {
  uniqueSectionName?: string;
  index?: number;
  pageAdTargetType: PageAdTargetValue;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 * @index - use when lb2 is rendered to keep only 1 lb1
 */
export function AdsLb1({
  uniqueSectionName,
  index,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
}: AdsLbProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <div
      className="flex flex-col xl:flex-row items-center justify-center max-w-full mb-4 min-h-[80px] lg:min-h-[120px]"
      style={stripeAdsBackground}
    >
      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(uniqueSectionName),
          id: index ? `dfp-ad-lb1-${index}` : "dfp-ad-lb1",
          rootClassName:
            "flex items-center [&_iframe]:max-w-screens-md sm:max-w-full",
          pubAdTargeting: [PubPageAdTarget],
          gsChannels,
          articleSlotTarget,
        }}
      />
    </div>
  );
}

export function AdsLb2({
  uniqueSectionName,
  index,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
}: AdsLbProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <div
      className="flex flex-col xl:flex-row items-center justify-center max-w-full mb-4 min-h-[80px] lg:min-h-[120px]"
      style={stripeAdsBackground}
    >
      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id: index ? `dfp-ad-lb2-${index}` : "dfp-ad-lb2",
          rootClassName:
            "flex items-center [&_iframe]:max-w-screens-md sm:max-w-full",
          pubAdTargeting: [PubPageAdTarget],
          gsChannels,
          articleSlotTarget,
        }}
      />
    </div>
  );
}

export function AdsLb3({
  uniqueSectionName,
  index,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
}: AdsLbProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <div
      className="flex flex-col xl:flex-row items-center justify-center max-w-full mb-4 min-h-[80px] lg:min-h-[120px]"
      style={stripeAdsBackground}
    >
      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.LB3,
          slot: GoogleAdsSlotFactory.lb3(uniqueSectionName),
          id: index ? `dfp-ad-lb3-${index}` : "dfp-ad-lb3",
          rootClassName:
            "flex items-center [&_iframe]:max-w-screens-md sm:max-w-full",
          pubAdTargeting: [PubPageAdTarget],
          gsChannels,
          articleSlotTarget,
        }}
      />
    </div>
  );
}
