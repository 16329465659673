import nextArrow from "@assets/actions/next-arrow-related-articles.svg";
import { isArrayWithElements } from "@src/app/helper/utils";
import { type RelatedArticles } from "@src/app/types/Opensearch";

import Heading from "../Typography/Heading";

export default function RelatedArticle({
  relatedArticles,
}: {
  relatedArticles: RelatedArticles;
}) {
  return (
    <>
      <Heading
        Element="h6"
        className="italic font-primary text-md lg:text-2md my-4 pt-sm border-t border-grey-850"
        testId={`related-article-header`}
      >
        Laporan berkaitan
      </Heading>
      {isArrayWithElements(relatedArticles) &&
        relatedArticles.map(({ id, urlPath, title }, index) => {
          return (
            <div
              key={id}
              className="flex items-stretch justify-between w-full h-fit bg-white-200 border-b-8 border-white-300"
              data-testid={`related-article-wrapper-${index}`}
            >
              <a
                data-testid={`related-article-title-${index}`}
                href={urlPath}
                className="hover:underline font-secondary text-sm text-black-200  font-regular lg:font-semibold lg:text-md p-6 w-full"
              >
                {title}
              </a>
              <a
                className="w-[60px] flex items-center bg-white-400"
                href={urlPath}
              >
                <img
                  src={nextArrow}
                  alt="next-arrow"
                  className="hover:cursor-pointer m-auto"
                />
              </a>
            </div>
          );
        })}
    </>
  );
}
