import { replaceDashAndWithSpace } from "@helper/stringTransform";
import { findStyleBySection } from "@src/app/helper/utils";
import { twMerge } from "tailwind-merge";

import Link from "./Link";

export type SectionTitleElement = "h1" | typeof Link;
type SectionTitleProps = {
  sectionName?: string;
  uniqueSectionName?: string;
  className?: string;
  Element?: SectionTitleElement;
};

export function SectionTitle({
  sectionName,
  uniqueSectionName = sectionName,
  className,
  Element = "h1",
}: SectionTitleProps) {
  const { selectedColor } = findStyleBySection(uniqueSectionName) || {};

  return sectionName ? (
    <Element
      destination={uniqueSectionName || ""}
      className={twMerge(
        "text-lg text-primary font-regular capitalize mb-2",
        `text-${selectedColor}`,
        className,
      )}
    >
      {replaceDashAndWithSpace(sectionName)}
    </Element>
  ) : null;
}
