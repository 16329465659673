import type { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

import useRemoveOverlay from "../hooks/useRemoveOverlay";

/**
 * Not found page: this will never render on Server.
 * Added only to handle client side error scenario.
 */
export default function NotFound(): ReactElement {
  useRemoveOverlay();
  return (
    <>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className="flex flex-col items-center mt-[160px]">
        <h1 className="text-md text-black-100 lg:text-lg">
          Sorry, the page you visited no longer exists.
        </h1>
        <p className="text-md text-black-100 lg:text-lg">
          Please visit our{" "}
          <span>
            <a href="/" className="underline text-blue-400">
              Home Page
            </a>
          </span>
        </p>
      </div>
    </>
  );
}
