import { isArrayWithElements } from "@src/app/helper/utils";
import useSwrApi from "@src/app/hooks/useSwrApi";
import type { ProcessedArticleData } from "@transformer/useOSResponse";

import ArticleCard from "../Card/Article/ArticleCard";
import LatestNewsHeader from "./LatestNewsHeader";

export default function LatestNews() {
  const { data: breakingNews } =
    useSwrApi<ProcessedArticleData[]>("breaking-news");

  return isArrayWithElements(breakingNews) ? (
    <div className="flex flex-col w-full px-6 mt-md">
      <LatestNewsHeader />
      <div className="flex flex-col w-full gap-y-lg mb-lg lg:min-h-[500px]">
        {breakingNews.map((article) => (
          <ArticleCard
            key={article.id}
            displayWidth={0}
            displayHeight={0}
            elements={{
              hasImage: false,
              isBreakingNews: true,
            }}
            overrideStyle={{
              dateStyle: "text-xs text-grey-400",
              contentWrapper: "flex flex-col-reverse mb-1 flex-grow basis-4/6",
              headerWrapper: "basis-2/6",
            }}
            {...article}
          />
        ))}
      </div>
    </div>
  ) : null;
}
