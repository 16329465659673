import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import ArticleCard from "@elements/Card/Article/ArticleCard";
import fetchBHApi from "@helper/fetchBHApi";
import HomeSectionBlock from "@src/app/components/Container/HomeSectionBlock";
import PodcastGallery, {
  PodcastBHCustomEventCategory,
} from "@src/app/components/Gallery/PodcastGallery";
import VideoGallery from "@src/app/components/Gallery/VideoGallery";
import { isArrayWithElements } from "@src/app/helper/utils";
import { type ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import React, { useEffect, useMemo, useState } from "react";

export type AllSectionsType = {
  [key in
    | "singapura"
    | "dunia"
    | "ekonomi-kerja"
    | "hidayah"
    | "rencana"
    | "wacana"
    | "sukan"
    | "gaya-hidup"]: ProcessedArticleData[];
};

export type HomeSectionsProps = {
  adsSectionName: string;
  renderedArticleIds: string;
};

export function HomeSections({
  adsSectionName,
  renderedArticleIds,
}: HomeSectionsProps) {
  const [filteredSections, setFilteredSections] = useState<AllSectionsType>();

  const sectionNames: (keyof AllSectionsType)[] = useMemo(
    () => [
      "singapura",
      "dunia",
      "ekonomi-kerja",
      "hidayah",
      "rencana",
      "wacana",
      "sukan",
      "gaya-hidup",
    ],
    [],
  );

  useEffect(() => {
    const fetchSections = async () => {
      const sectionsData = Object.assign(
        {},
        ...(await Promise.all(
          sectionNames.map(async (sectionName) => {
            const size =
              sectionName === "sukan" || sectionName === "gaya-hidup" ? 4 : 3;
            const data = await fetchBHApi<ProcessedArticleData[]>(
              `section/${sectionName}?size=${size}&charCount=130&excludeIds=${renderedArticleIds}`,
              "GET",
            );
            return { [sectionName]: data };
          }),
        )),
      ) as AllSectionsType;

      setFilteredSections(sectionsData);
    };

    void fetchSections();
  }, [sectionNames, renderedArticleIds]);

  const sectionProps = {
    displayWidth: 500,
    displayHeight: 333,
    elements: { hasIcon: true, hasParagraph: true },
    overrideStyle: {
      imageStyle: "w-full",
      // titleStyle: "text-sm",
      headerWrapper: "basis-4/12",
      cardWrapper:
        "flex flex-col gap-2 w-full min-w-[275px] md:min-w-0 md:flex-row lg:max-w-max lg:min-h-[190px]",
      description: "hidden lg:flex text-xxs",
      contentWrapper: "gap-1 basis-8/12",
    },
  };

  const lastTwoSection = {
    displayWidth: 290,
    displayHeight: 193,
    elements: { hasIcon: true, hasParagraph: false, hasSectionName: false },
    overrideStyle: {
      imageStyle: "w-full",
      cardWrapper: "flex flex-col gap-2 min-w-[275px] md:min-w-0 md:w-full",
      headerWrapper: "md:max-w-[275px]",
    },
  };

  const { parent, child, lastSection } = {
    parent: "block md:flex w-full gap-lg my-2",
    child:
      "flex md:flex-col gap-2 w-full overflow-x-scroll lg:overflow-x-hidden",
    lastSection:
      "flex gap-2 lg:gap-lg w-full overflow-x-scroll xl:overflow-x-hidden",
  };

  return (
    <>
      {Array.from({
        length: Math.ceil(sectionNames.slice(0, -2).length / 2),
      }).map((_, index) => {
        const startIndex = index * 2;
        const sectionName1 = sectionNames[startIndex] as keyof AllSectionsType;
        const sectionName2 = sectionNames[
          startIndex + 1
        ] as keyof AllSectionsType;
        return (
          <React.Fragment key={index}>
            {filteredSections &&
              isArrayWithElements(filteredSections[sectionName1]) && (
                <div className={parent}>
                  <HomeSectionBlock sectionName={sectionName1}>
                    <div className={child}>
                      {filteredSections[sectionName1].map((context) => (
                        <ArticleCard
                          key={context.id}
                          {...sectionProps}
                          {...context}
                        />
                      ))}
                    </div>
                  </HomeSectionBlock>
                  <HomeSectionBlock sectionName={sectionName2}>
                    <div className={child}>
                      {filteredSections[sectionName2].map((context) => (
                        <ArticleCard
                          key={context.id}
                          {...sectionProps}
                          {...context}
                        />
                      ))}
                    </div>
                  </HomeSectionBlock>
                </div>
              )}
            {index === 0 && (
              <div className="flex flex-col gap-2md lg:gap-0">
                <AdsLb2
                  uniqueSectionName={adsSectionName}
                  pageAdTargetType={PageAdTargetingTypeEnum.HOME}
                />
                <PodcastGallery
                  bhCustomEventProps={{
                    eventCategory: PodcastBHCustomEventCategory.HOME_PAGE,
                    eventAction: "click",
                  }}
                />
              </div>
            )}
            {index === 1 && <VideoGallery />}
          </React.Fragment>
        );
      })}

      {isArrayWithElements(filteredSections?.sukan) && (
        <HomeSectionBlock sectionName="sukan" className="w-full">
          <div className={lastSection}>
            {filteredSections.sukan.map((context) => (
              <ArticleCard key={context.id} {...lastTwoSection} {...context} />
            ))}
          </div>
        </HomeSectionBlock>
      )}

      {isArrayWithElements(filteredSections?.["gaya-hidup"]) && (
        <HomeSectionBlock sectionName="gaya-hidup" className="w-full">
          <div className={lastSection}>
            {filteredSections["gaya-hidup"].map((context) => (
              <ArticleCard key={context.id} {...lastTwoSection} {...context} />
            ))}
          </div>
        </HomeSectionBlock>
      )}
    </>
  );
}
