import fetcher from "@src/app/helper/fetcher";
import useSWR, { SWRResponse } from "swr";

import { getWindowOrigin } from "../helper/utils";

export default function useSwrApi<T>(endpoint: string): SWRResponse<T, Error> {
  const url = `${getWindowOrigin()}/_plat/api/v1/${endpoint}`;
  const swrRes: SWRResponse<T, Error> = useSWR<T, Error>(url, fetcher, {
    onError: (error) => {
      console.error(`Error fetching data from ${url}:`, error);
    },
  });
  return swrRes;
}
