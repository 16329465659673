import { ArticlePageQueryByUrl } from "@caas/ArticlePageQueries";
import { SectionPagePaywallQuery } from "@caas/SectionPageQueries";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import fetchBHApi from "@helper/fetchBHApi";
import fetchCass from "@helper/fetchCaas";
import getOSProcessedArticleData, {
  ProcessedArticleData,
} from "@hooks/transformer/useOSResponse";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { UrlMatch } from "@src/app/data";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { RouteParam, UgcArticleRouteParam } from "@typings/Data";
import type {
  OSResponse,
  PartialCueArticle,
  PartialCueArticleWithCampaign,
} from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

export const isMatchResult = (
  a: Match<RouteParam>,
): a is MatchResult<UgcArticleRouteParam> => {
  return (
    "id" in ((a as MatchResult<UgcArticleRouteParam>).params as RouteParam)
  );
};

export type PayloadResponseType = {
  article: ProcessedArticleData;
  latestRegisteredArticle: ProcessedArticleData[];
};

export const fetchUgcArticleData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<PayloadResponseType | null, string>
> => {
  const params = isMatchResult(urlComponent) ? urlComponent.params : undefined;
  if (!params) {
    return {
      type: ResponseType.CLIENT_ERROR,
      statusCode: 404,
    };
  }

  const urlToQuery = `/mata-mata/${params.campaignId}/${params.id}`;

  try {
    const data = (await fetchCass({
      searchQuery: ArticlePageQueryByUrl(urlToQuery),
    })) as OSResponse<PartialCueArticleWithCampaign>;

    const LatestArticleByPaywall = (await fetchCass({
      searchQuery: SectionPagePaywallQuery(
        LabelCardIconVariety.REGISTERED_USER,
      ),
    })) as OSResponse<PartialCueArticle>;

    const hits = data.payload.hits.total.value;
    if (hits === 0) {
      return {
        type: ResponseType.CLIENT_ERROR,
        statusCode: 404,
      };
    }

    const returnedArticleUrlPath =
      data.payload.hits.hits[0]._source?.data?.context?.urlPath;
    if (returnedArticleUrlPath && returnedArticleUrlPath !== urlToQuery) {
      return {
        type: ResponseType.REDIRECT,
        statusCode: 301,
        target: returnedArticleUrlPath,
      };
    }

    let partial = true;
    if (
      data.payload.hits.hits[0]._source?.data?.context?.paywall
        .contentAccess === LabelCardIconVariety.FREE
    ) {
      partial = false;
    }

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        article: getOSProcessedArticleData(data, partial)[0],
        latestRegisteredArticle: getOSProcessedArticleData(
          LatestArticleByPaywall,
        ),
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchNextCampaignStoryData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[]> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "get-campaign-article",
    "POST",
    {
      campaignReferenceId: requestData.filterArticlesBasedOn,
      excludeIds: requestData.excludeIds,
    },
  );

  return response;
}
