/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { ClientSideSecrets } from "@src/app/featureFlag/featureFlags";
import {
  BC_PLAYER_ID,
  BC_PLAYER_ID_SMALL_WIDGET,
} from "@src/app/helper/getEnvVariables";
import { getFeatureFlag } from "@src/app/helper/utils";
import {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";

import styles from "./BrightcoveVideo.module.css";

type BrightcoveVideoProps = {
  videoId: string;
  forwardedRef?: RefObject<HTMLDivElement>;
  videoRef?: RefObject<HTMLDivElement>;
  smallWidget?: boolean;
  onEnded?: () => void;
  nextPlaylist?: () => void;
};

export function BrightcoveVideo({
  videoId,
  forwardedRef,
  videoRef,
  smallWidget,
  onEnded,
  nextPlaylist,
}: BrightcoveVideoProps): ReactElement {
  /**
   * @param {Object} success - The success object containing reference to the player.
   * @param {ReactPlayer} success.ref - Reference to the player.
   */

  interface VideoPlayerRef {
    on(event: string, callback: () => void): void;
    el(): HTMLElement;
  }

  const handleSuccess = function (success: { ref: VideoPlayerRef }) {
    const player = success.ref;
    if (onEnded) {
      player.on("ended", () => {
        onEnded();
      });
    }
    const nextButton = player.el().querySelector(".vjs-next-button");
    if (nextButton) {
      nextButton.addEventListener("click", () => {
        if (nextPlaylist) {
          nextPlaylist();
        }
      });
    }
  };

  const [accountId, setAccountID] = useState<string>("");

  const fetchData = useCallback(async () => {
    const data = await getFeatureFlag(ClientSideSecrets.BC_ACCOUNT_ID);
    setAccountID(data);
  }, []);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  return (
    <>
      {accountId && (
        <div
          key={videoId}
          data-testid={videoId}
          className={styles.brightcove}
          ref={forwardedRef}
        >
          <ReactPlayerLoader
            style={{ gridColumn: 1, gridRow: 1 }}
            accountId={accountId}
            playerId={smallWidget ? BC_PLAYER_ID_SMALL_WIDGET : BC_PLAYER_ID}
            videoId={videoId}
            videoRef={videoRef}
            onSuccess={handleSuccess}
          />
        </div>
      )}
    </>
  );
}
