import CASBlock from "@components/CASBlock/CASBlock";
import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import ArticleCard, {
  ArticleCardStyleProps,
} from "@elements/Card/Article/ArticleCard";
import { CAS_UGC_SECTION_BANNER_ID } from "@helper/getEnvVariables";
import { fetchEnvelope, useLiveRamp } from "@hooks/useLiveRamp";
import type { BHBasePageProps } from "@pages/BHBasePage";
import BHBasePage from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import styles from "@pages/UGC/UGCLandingPage/UGCLandingPage.module.css";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetingTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";
import React from "react";

import { UGCLandingPayloadResponseType } from "./UGCLandingPage.server";
import UGCLandingPageMetaData from "./UGCLandingPageMetaData";
import UGCMonthlyCampaignList from "./UGCMonthlyCampaignList";

export const MATA_MATA_SECTION = "mata-mata";

export function UGCLandingPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<UGCLandingPayloadResponseType, string>
  > = useRouteContext();
  useLiveRamp({ fetchEnvelope });
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const { regular, monthly }: UGCLandingPayloadResponseType =
    dataLoaderResponse.payload;

  const { gaData, metaTags } = UGCLandingPageMetaData();

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: MATA_MATA_SECTION,
    hasLb1Ads: false,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  const overrideStyle: ArticleCardStyleProps = {
    cardWrapper: "flex flex-col",
    contentWrapper:
      "flex flex-col-reverse md:flex-col mt-2 md:mt-0 md:mb-1 flex-grow",
    imageStyle: "w-full",
  };

  const listingPageAdTargeting = pubPageAdTargeting[
    pageAdTargetValue
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <BHBasePage {...basePageProps}>
      <h3 className={styles.sectionName}>{MATA_MATA_SECTION}</h3>
      <CASBlock
        className="min-h-[530px] lg:min-h-[495px]"
        casId={CAS_UGC_SECTION_BANNER_ID}
      />
      <div className={styles.ugcContentWrapper}>
        <div className={styles.ugcCampaignLists}>
          {/* Render regular campaigns. */}
          {regular.map((context, index) => {
            const shouldDisplayAdAfterSecondItem = index === 1;
            const dynamicClass =
              index % 2 === 0 ? styles.evenItem : styles.oddItem;
            return (
              <React.Fragment key={index}>
                <div className={`${styles.campaignCards} ${dynamicClass}`}>
                  <ArticleCard
                    displayWidth={454}
                    displayHeight={303}
                    elements={{
                      hasParagraph: true,
                      dateVariant: "dash",
                    }}
                    overrideStyle={overrideStyle}
                    {...context}
                  />
                </div>
                {shouldDisplayAdAfterSecondItem && (
                  <div className={styles.ugcAdsMainContent}>
                    <Advertisement
                      rootClassName="flex justify-center"
                      adUnitProps={{
                        type: AdvertisementTypeEnum.LB3,
                        slot: GoogleAdsSlotFactory.lb3(MATA_MATA_SECTION),
                        pubAdTargeting: [listingPageAdTargeting],
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
          {/* Render monthly campaigns. */}
          <UGCMonthlyCampaignList
            monthlyCampaigns={monthly}
            ugcSection={MATA_MATA_SECTION}
            overrideStyle={overrideStyle}
          />
        </div>
        <div className={styles.ugcAdsSideBar}>
          <Advertisement
            adUnitProps={{
              type: AdvertisementTypeEnum.IMU2,
              slot: GoogleAdsSlotFactory.imu2(MATA_MATA_SECTION),
              pubAdTargeting: [listingPageAdTargeting],
            }}
          />
        </div>
      </div>
    </BHBasePage>
  );
}
