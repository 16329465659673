import { RestaurantDataWithDistance } from "@src/app/types/foodMapTypes";

export default function UpdateRestaurantIndex(
  restaurantData: RestaurantDataWithDistance[],
) {
  const indexedData = restaurantData.map((restaurant, index) => {
    return {
      ...restaurant,
      index: index + 1,
    };
  });
  return indexedData;
}
