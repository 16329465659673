import SPHLogoFooter from "@assets/footer/logo_footer.svg";
import { MainContainer } from "@src/app/components/Container/MainContainer";
import type { ReactElement } from "react";

import { FooterCopyright } from "./FooterCopyright";
import {
  FOOTER_APP_DOWNLOAD_MENU,
  FOOTER_SOCIAL_MENU,
  FOOTER_SUB_MENU,
  FOOTER_TOP_MENU,
} from "./footerMenuConstants";
import { FooterMenuList } from "./FooterMenuList";

export type FooterProps = {
  testId?: string;
};

export function Footer({ testId = "footer" }: FooterProps): ReactElement {
  return (
    <>
      <div
        data-testid={`${testId}-footerContainer`}
        className="py-xl px-0 mt-xl bg-gradient-to-r from-blue-100 to-blue-100 text-white-100"
      >
        <MainContainer className="px-sm">
          <div className="flex flex-col md:flex-row">
            <div className="sm:max-w-[320px] sm:pr-sm">
              <img src={SPHLogoFooter} alt="footer-logo" width="250px"></img>

              <FooterMenuList
                testId={testId}
                items={FOOTER_SOCIAL_MENU}
                type="social"
              />
              <FooterMenuList
                testId={testId}
                items={FOOTER_TOP_MENU}
                type="top"
              />
              <FooterMenuList
                testId={testId}
                items={FOOTER_SUB_MENU}
                type="eshop"
              />
              <FooterCopyright className="hidden lg:block" testId={testId} />
            </div>

            <div className="lg:flex lg:flex-col lg:gap-2 lg:justify-end max-w-full lg:max-w-[290px]">
              <h4 className="font-primary font-semibold">
                Berita Harian di Mudah Alih
              </h4>
              <FooterMenuList
                testId={testId}
                items={FOOTER_APP_DOWNLOAD_MENU}
                type="appDownload"
              />
              <h4 className="font-primary px-0 mt-md font-semibold">
                Khidmat pelangganan
              </h4>
              <a
                href="callto:63883838"
                className="text-white-100 text-sm leading-[150%] font-thin hover:underline"
              >
                6388-3838
              </a>
              <h4 className="font-primary px-0 font-semibold">Meja Berita</h4>
              <p className="text-white-100 font-primaryLight text-sm leading-[150%] mt-0 font-thin mb-4">
                Anda ada maklumat mengenai sesuatu berita menarik?
                <br />
                E-mel: bhnews@sph.com.sg{" "}
              </p>
              <FooterCopyright className="block lg:hidden" testId={testId} />
            </div>
          </div>
        </MainContainer>
      </div>
    </>
  );
}
