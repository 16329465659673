import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import LabelCardIcon from "@components/Icon/LabelCardIcon";
import { Image } from "@elements/ResponsiveImage/Image";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { HeadingElements } from "@elements/Typography/Heading";
import { RenderTags } from "@elements/Typography/Tags";
import getFallbackSectionImage from "@helper/getFallbackSectionImage";
import LabelCardIconNew from "@src/app/components/HomePageStoryPackages/Label/LabelCardIconNew";
import { findStyleBySection } from "@src/app/helper/utils";
import { RawImageField } from "@src/app/types/Opensearch";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type ArticleCardStyleProps = {
  cardWrapper?: string;
  contentWrapper?: string;
  headerWrapper?: string;
  hero?: string;
  description?: string;
  dateStyle?: string;
  titleStyle?: string;
  imageStyle?: string;
  tagWrapper?: string;
  sectionNameWrapper?: string;
  sectionNameItem?: string;
  iconStyle?: string;
  sectionNameStyle?: string;
  dateTitleWrapper?: string;
};

export type ArticleCardHTMLProps = {
  headingElement: HeadingElements;
};

export type ArticleCardElementProps = {
  hasHeader?: boolean;
  hasIcon?: boolean;
  hasNewIcon?: boolean;
  hasImage?: boolean;
  hasParagraph?: boolean;
  hasSectionName?: boolean;
  hasTitle?: boolean;
  isBreakingNews?: boolean;
  dateVariant?: "dash" | "or";
  hasTags?: boolean;
  hasDateSideBySide?: boolean;
};

export type ArticleCardProps = {
  linkTo: string;
  title: string;
  paragraph?: string;
  date: string;
  time: string;
  displayWidth: number;
  displayHeight: number;
  imageField?: RawImageField;
  imageAlt: string;
  sectionName?: string;
  uniqueSectionName?: string;
  linkToSection?: string;
  contentAccess?: LabelCardIconVariety;
  elements?: ArticleCardElementProps;
  overrideStyle?: ArticleCardStyleProps;
  htmlElements?: ArticleCardHTMLProps;
  urlPath?: string;
  tags?: {
    alias?: string;
    name?: string;
  }[];
};

export default function ArticleCard({
  title,
  date,
  time,
  paragraph,
  displayWidth,
  displayHeight,
  imageField,
  imageAlt,
  linkTo,
  sectionName,
  uniqueSectionName,
  linkToSection = "",
  contentAccess,
  htmlElements,
  elements,
  overrideStyle,
  urlPath,
  tags,
}: ArticleCardProps) {
  const {
    cardWrapper,
    contentWrapper,
    headerWrapper,
    hero,
    description,
    dateStyle,
    titleStyle,
    imageStyle,
    tagWrapper,
    sectionNameWrapper,
    sectionNameItem,
    iconStyle,
    sectionNameStyle,
    dateTitleWrapper,
  } = overrideStyle || {};

  const {
    hasHeader,
    hasIcon,
    hasNewIcon,
    hasParagraph,
    isBreakingNews,
    hasSectionName,
    dateVariant,
    hasTags,
    hasDateSideBySide,
    hasTitle = true,
    hasImage = true,
  } = elements || {};

  const { headingElement } = htmlElements || {};
  const { selectedColor } = findStyleBySection(uniqueSectionName) || {};
  const dummyImage = getFallbackSectionImage(sectionName);
  const textColor = selectedColor ? `text-${selectedColor}` : "";

  return (
    <div className={cardWrapper ?? "flex md:flex-col flex-row gap-sm md:gap-0"}>
      <div
        className={twMerge(
          "flex md:flex-col flex-row gap-sm md:gap-0",
          headerWrapper,
        )}
      >
        {isBreakingNews && (
          <Link to={linkToSection}>
            <span className={twMerge(`capitalize hover:underline`, textColor)}>
              {sectionName}
            </span>
          </Link>
        )}
        {hasImage && (
          <Link
            to={urlPath ? urlPath : linkTo}
            reloadDocument
            data-testid="link"
            className={twMerge(
              "flex-shrink-0 md:flex-grow-0 md:w-full w-[120px]",
              imageStyle,
            )}
          >
            {hasHeader && (
              <Heading
                data-testid="title-header"
                className="text-[30px] font-bold font-secondary hover:cursor-pointer hover:underline text-black-200 leading-lg mb-xs"
              >
                {title}
              </Heading>
            )}
            <Image
              displayWidth={displayWidth}
              srcWidth={displayWidth}
              srcHeight={displayHeight}
              src={imageField?.url ?? dummyImage}
              alt={imageAlt}
              dataTestId="hero"
              className={hero ? hero : "mb-sm"}
            />
          </Link>
        )}
      </div>

      <div
        className={twMerge(
          "flex flex-col gap-1 mb-1 flex-grow",
          contentWrapper,
        )}
      >
        <div className={twMerge("flex items-center", sectionNameWrapper)}>
          {hasSectionName && (
            <div
              className={twMerge(
                "flex items-center lg:justify-between flex-wrap",
                hasDateSideBySide && "w-full",
                sectionNameItem,
              )}
            >
              <span
                className={twMerge(
                  `mr-2 lg:mr-4 text-xxs lg:text-sm capitalize hover:underline text-nowrap ${textColor}`,
                  sectionNameStyle,
                )}
              >
                <Link to={linkToSection}>{sectionName}</Link>
              </span>
              {hasDateSideBySide && (
                <Date
                  date={date}
                  time={time}
                  variant={dateVariant}
                  className={dateStyle}
                />
              )}
            </div>
          )}
          {hasIcon && (
            <LabelCardIcon
              className={iconStyle}
              contentAccess={contentAccess}
            />
          )}
          {hasNewIcon && (
            <LabelCardIconNew
              className={iconStyle}
              contentAccess={contentAccess}
            />
          )}
        </div>
        <div className={dateTitleWrapper}>
          {!hasDateSideBySide && (
            <Date
              date={date}
              time={time}
              variant={dateVariant}
              className={dateStyle}
            />
          )}
          {hasTitle && (
            <Link to={urlPath ? urlPath : linkTo} reloadDocument>
              <Heading
                Element={headingElement}
                data-testid="title-story"
                className={twMerge(
                  "font-secondary hover:cursor-pointer hover:underline text-sm md:text-md text-black-200 font-bold leading-[23px]",
                  titleStyle,
                )}
              >
                {title}
              </Heading>
            </Link>
          )}
        </div>
        {hasParagraph && (
          <span
            data-testid="paragraph"
            className={
              (twMerge(
                "font-primary text-xxs md:text-xs leading-[150%] font-regular mb-sm overflow-hidden",
              ),
              description)
            }
          >
            {paragraph}
          </span>
        )}
        {hasTags && <RenderTags tags={tags} tagsWrapper={tagWrapper} />}
      </div>
    </div>
  );
}
