import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import {
  CreateLoadMoreHandler,
  CreateLoadMoreHandlerProps,
} from "@elements/LoadMore/CreateLoadMoreHandler";
import LoadMore from "@elements/LoadMore/LoadMore";
import debounce from "@helper/debounce";
import { isArrayWithElements, isClientSide } from "@helper/utils";
import { isElementInViewPort } from "@helper/visibilityHelpers";
import { BASE_URL } from "@src/api/constant";
import { getTagsName } from "@src/app/components/Elements/GAData/GADataHelper";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { ArticleAdTargetKeys, PageAdTargetValue } from "@typings/Ads.d";
import {
  FetchMoreArticlesFunction,
  MoreArticlesRequestData,
} from "@typings/MoreArticlesApi";
import React, { useCallback, useEffect, useState } from "react";

import { MATA_MATA_SECTION } from "../UGCLandingPage/UGCLandingPage";
import UGCArticleContentCard from "./UGCArticleContentCard";
import { fetchNextCampaignStoryData } from "./UGCArticlePage.server";

type UGCArticleInfiniteScrollProps = {
  mainArticle: ProcessedArticleData;
  latestRegisteredArticle: ProcessedArticleData[];
  pageAdTargetValue: PageAdTargetValue;
  onNewArticle: (article: ProcessedArticleData) => void;
  isOutbrainEnabled: boolean;
};

export default function UGCArticleInfiniteScroll({
  mainArticle,
  latestRegisteredArticle,
  pageAdTargetValue,
  onNewArticle,
  isOutbrainEnabled = false,
}: UGCArticleInfiniteScrollProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>([mainArticle.id]),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [visibleArticleId, setVisibleArticleId] = useState<string>(
    mainArticle.id,
  );
  const [currentPath, setCurrentPath] = useState<string>(
    mainArticle.urlPath || "",
  );
  const [updatedPathForArticleId, setUpdatedPathForArticleId] =
    useState<string>();

  const filterArticlesBasedOn = mainArticle.campaignReference?.id;

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? mainArticle : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn,
      excludeIds: [...renderedArticleIds].join(","),
    };
    const fetchFunction: FetchMoreArticlesFunction = fetchNextCampaignStoryData;
    const updateGADataForArticle = true;
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: 1,
      pageNumber,
      updateGADataForArticle,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);

    if (isArrayWithElements(response)) {
      const article = response[0];
      setVisibleArticleId(article.id);
      setUpdatedPathForArticleId(article.id);
      setCurrentPath(article.urlPath || "");
      onNewArticle(article);
    } else {
      setHasMoreStories(false);
    }

    return response;
  };

  const handleScroll = useCallback(() => {
    if (!isClientSide) return;

    const articleCards = document.querySelectorAll(".article-card");

    articleCards.forEach((article) => {
      if (article.id !== updatedPathForArticleId) {
        if (isElementInViewPort(article, 20)) {
          setVisibleArticleId(article.id);
        }
      }
    });
  }, [updatedPathForArticleId]);

  useEffect(() => {
    if (!isClientSide) return;
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isClientSide) {
      const articleData = [mainArticle, ...loadMoreData].find(
        (article) => article.id === visibleArticleId,
      );

      if (articleData) {
        const newPath = articleData.urlPath;
        if (newPath !== currentPath) {
          window.history.pushState("article", articleData.title, newPath);
          setCurrentPath(newPath || "");
          setUpdatedPathForArticleId(articleData.id);
          onNewArticle(articleData);
        }
      }
    }
  }, [visibleArticleId, loadMoreData, mainArticle, onNewArticle, currentPath]);

  return (
    <div>
      {[...loadMoreData].map((context, index) => (
        <React.Fragment key={index}>
          <CompanionAdsLb
            uniqueSectionName={MATA_MATA_SECTION}
            index={index + 1}
            pageAdTargetType={pageAdTargetValue}
            gsChannels={context.gsChannels}
            articleSlotTarget={[
              { key: ArticleAdTargetKeys.ARTICLE_ID, value: context.id },
              {
                key: ArticleAdTargetKeys.TAGS,
                value: getTagsName(context.tags || []).toLowerCase(),
              },
            ]}
          />
          <div id={context.id} className="article-card">
            <UGCArticleContentCard
              key={index}
              mainArticle={context}
              latestRegisteredArticle={latestRegisteredArticle}
              pageAdTargetValue={pageAdTargetValue}
              pageNumber={index + 1}
              isInfiniteScrollArticle={true}
              isOutbrainEnabled={isOutbrainEnabled}
              gsChannels={context.gsChannels}
              articleSlotTarget={[
                { key: ArticleAdTargetKeys.ARTICLE_ID, value: context.id },
                {
                  key: ArticleAdTargetKeys.TAGS,
                  value: getTagsName(context.tags || []).toLowerCase(),
                },
              ]}
              index={index}
            />
          </div>
        </React.Fragment>
      ))}
      <LoadMore
        rootClassName="my-4"
        onLoadMore={handleLoadMore}
        hasMore={hasMoreStories}
        loadText="Lagi Cerita"
        articleEventLabel={true}
        landingPage={`${BASE_URL}${mainArticle.urlPath}`}
      />
    </div>
  );
}
