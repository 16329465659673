import SPHLogoMobile from "@assets/BHLogoMobile.svg";
import SPHLogo from "@assets/BHLogoStandard.svg";
import { MiscLinks } from "@components/HeaderNew/MiscLinks";
import { PrayerTime } from "@components/HeaderNew/PrayerTime/PrayerTime";
import { SectionQuickLinks } from "@components/HeaderNew/SectionQuickLinks";
import type { ReactElement } from "react";
import { useState } from "react";

import { SearchButton } from "./Search/SearchButton";

export type HeaderProps = Readonly<{
  testId?: string;
}>;
import FoodMapHeader from "@components/FoodMap/FoodMapElements/FoodMapHeader";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { NoSSR } from "@sphtech/web2-core/components";
import { getCurrentDate } from "@src/app/helper/useDateFormat";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";

import { MobileMenuIcon } from "./MobileMenuIcon";
import { MobileMenuModal } from "./MobileMenuModal";
import MySphLoginButton from "./MySphLoginButton";
import { SearchInput } from "./Search/SearchInput";
import UserInfo from "./UserInfo/UserInfo";

export function HeaderNew({ testId = "header" }: HeaderProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const [expanded, setExpanded] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleMobileMenu = () => {
    setShowMenuIcon((prevShowMenuIcon) => !prevShowMenuIcon);
    setExpandMenu((prevExpandMenu) => !prevExpandMenu);
    setExpanded(false);
  };

  // Format - Monday, Feb 26, 2024.
  const date = getCurrentDate("EEEE, MMM d, yyyy");
  const subLink =
    "https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-header";

  return (
    <>
      <div
        data-testid={`${testId}-outerContainer`}
        className="flex flex-col font-primary text-grey-800 font-sm font-regular h-auto w-full my-0 mx-auto sticky top-0 bg-white-100 z-[51]"
      >
        <div
          data-testid={`${testId}-innerContainer`}
          className="lg:max-w-screen-lg xl:max-w-screen-xl w-full mx-auto h-[39px] lg:h-auto py-0 lg:py-2 lg:pt-4 px-xs lg:px-xxs xl:px-0 bg-gradient-to-r from-blue-400 to-blue-450 lg:bg-white-100 lg:from-white-100 lg:to-white-100"
        >
          <div className="flex h-[39px] lg:h-[70px] justify-between lg:justify-normal">
            <div className="basis-2/6 flex-col hidden lg:flex">
              <div data-testid={`${testId}-subscriptionLink`}>
                <a
                  href={subLink}
                  className="bg-blue-400 text-white-200 font-regular text-xxs relative block w-[130px] h-[18px] text-center"
                >
                  <span className="absolute top-0 right-0 h-0 w-0 border-l-[18px] border-b-white-100 border-b-[18px] border-l-blue-400"></span>
                  Langgan
                </a>
              </div>
              <span className="flex-grow flex items-end text-xxs text-grey-350">
                {date}
              </span>
            </div>
            <div className="basis-3/6 lg:basis-2/6 self-center lg:self-end lg:flex lg:justify-center">
              <a
                href="/"
                data-testid={`${testId}-logo`}
                className="flex w-auto h-[25px] lg:w-[288px] lg:h-[55px]"
              >
                <img
                  src={SPHLogo}
                  alt="SPH Logo"
                  className="h-[30px] w-auto lg:h-[55px] lg:w-full hidden lg:block"
                />
                <div className="lg:hidden">
                  <img
                    src={SPHLogoMobile}
                    alt="SPH Logo mobile"
                    width={132}
                    height={25}
                    className="lg:hidden"
                  />
                </div>
              </a>
            </div>
            <div className="basis-2/6 hidden lg:flex justify-end">
              <div className="flex flex-col h-full">
                <div className="flex self-end">
                  <MiscLinks testId={testId} />
                  {userDisplayName ? (
                    <div className="relative">
                      <UserInfo
                        rootClassName="flex itmes-center"
                        userInfo={
                          {
                            ...userInfo,
                            lastname: userDisplayName,
                            loginId: OKTAUserInfo.loginid,
                            sub: OKTAUserInfo.reguserstatus,
                          } as UserInfoType
                        }
                      />
                    </div>
                  ) : (
                    <MySphLoginButton
                      buttonStyle="text-[12px] text-blue-400"
                      iconWidth="12px"
                      iconHeight="12px"
                    />
                  )}
                </div>
                <NoSSR>
                  <div className="flex-grow hidden lg:flex self-end items-end">
                    <PrayerTime
                      currentDate={new Date().toLocaleDateString("en-GB")}
                    />
                  </div>
                </NoSSR>
              </div>
            </div>
            <div className="basis-3/6 flex gap-1 w-[100px] h-[39px] justify-end items-center lg:hidden">
              <FoodMapHeader
                BHLogo={false}
                FoodMapLink={true}
                wrapperClass="!px-sm !py-2 py-xs border-b-[3px] border-red-100"
                newMasthead={true}
              />
              <div className="border-x-[0.5px] border-grey-1000">
                <SearchButton
                  expanded={expanded}
                  onClick={toggleExpanded}
                  isMobile={true}
                />
              </div>
              <MobileMenuIcon
                showMenuIcon={showMenuIcon}
                onClick={toggleMobileMenu}
              />
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <SearchInput expanded={expanded} isMobile={true} />
        </div>
        <SectionQuickLinks testId={testId} />
      </div>
      <div data-testid="mobile-foodmap-prayertime" className="lg:hidden mb-xs">
        <div className="flex h-[32px] justify-between items-center px-xs bg-white-200">
          <span className="text-[11px] text-grey-350 font-semibold">
            {date}
          </span>
          <a href={subLink} className="text-xxs text-blue-400 font-regular">
            Langgan
          </a>
        </div>
        <NoSSR>
          <div className="flex items-center justify-center h-[42px] bg-grey-450">
            <PrayerTime
              currentDate={new Date().toLocaleDateString("en-GB")}
              isMobile
            />
          </div>
        </NoSSR>
      </div>

      <MobileMenuModal expandMenu={expandMenu} onClick={toggleMobileMenu} />
    </>
  );
}
