import SPHLogo from "@assets/BeritaHarianLogo.svg";
import { PrayerTime } from "@header/PrayerTime/PrayerTime";
import { MiscLinks } from "@src/app/components/Header/MiscLinks";
import { SecondaryLinks } from "@src/app/components/Header/SecondaryLinks";
import { SectionQuickLinks } from "@src/app/components/Header/SectionQuickLinks";
import type { ReactElement } from "react";
import { useState } from "react";

import { SearchButton } from "./Search/SearchButton";

export type HeaderProps = Readonly<{
  testId?: string;
}>;
import FoodMapHeader from "@components/FoodMap/FoodMapElements/FoodMapHeader";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { NoSSR } from "@sphtech/web2-core/components";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";

import { MobileMenuIcon } from "./MobileMenuIcon";
import { MobileMenuModal } from "./MobileMenuModal";
import MySphLoginButton from "./MySphLoginButton";
import { Search } from "./Search/Search";
import { SearchInput } from "./Search/SearchInput";
import UserInfo from "./UserInfo/UserInfo";

export function Header({ testId = "header" }: HeaderProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const [expanded, setExpanded] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleMobileMenu = () => {
    setShowMenuIcon((prevShowMenuIcon) => !prevShowMenuIcon);
    setExpandMenu((prevExpandMenu) => !prevExpandMenu);
  };

  return (
    <>
      <div
        data-testid={`${testId}-outerContainer`}
        className="flex flex-col font-primary text-grey-800 font-sm font-regular h-auto w-full max-w-screen-xl my-0 mx-auto sticky top-0 bg-white-100 z-50"
      >
        <div
          data-testid={`${testId}-innerContainer`}
          className="flex items-center h-auto justify-between py-2 lg:py-4 px-sm lg:px-0"
        >
          <a
            href="/"
            data-testid={`${testId}-logo`}
            className="w-[157px] h-[30px] lg:w-[330px] lg:h-[63px]"
          >
            <img
              src={SPHLogo}
              alt="SPH Logo"
              className="h-[30px] w-auto lg:h-auto lg:w-full"
            />
          </a>
          <NoSSR>
            <div className="h-full hidden lg:block self-end">
              <PrayerTime
                currentDate={new Date().toLocaleDateString("en-GB")}
              />
            </div>
          </NoSSR>
          <div className="flex w-[100px] h-[42px] justify-end items-center lg:hidden">
            <SearchButton
              expanded={expanded}
              onClick={toggleExpanded}
              isMobile={true}
            />
            <MobileMenuIcon
              showMenuIcon={showMenuIcon}
              onClick={toggleMobileMenu}
            />
          </div>
        </div>
        <SearchInput expanded={expanded} isMobile={true} />
        <SectionQuickLinks testId={testId} />
        <div
          data-testid={`${testId}-headerSearch`}
          className="hidden lg:relative lg:block"
        >
          <Search expanded={expanded} onClick={toggleExpanded} />
        </div>
      </div>
      <div data-testid="mobile-foodmap-prayertime" className="md:hidden mb-xs">
        <FoodMapHeader
          BHLogo={false}
          FoodMapLink={true}
          wrapperClass="!px-sm !py-2 py-xs border-b-[3px] border-red-100"
        />
        <NoSSR>
          <div className="flex justify-center h-[45px] md:hidden">
            <PrayerTime currentDate={new Date().toLocaleDateString("en-GB")} />
          </div>
        </NoSSR>
      </div>
      <div
        data-testid={`${testId}-secondaryNav`}
        className="hidden lg:flex lg:justify-between"
      >
        <SecondaryLinks testId={testId} />
        <MiscLinks testId={testId} />
        {userDisplayName ? (
          <UserInfo
            rootClassName="text-white whitespace-nowrap pt-[18px] pb-[10px]"
            userInfo={
              {
                ...userInfo,
                lastname: userDisplayName,
                loginId: OKTAUserInfo.loginid,
                sub: OKTAUserInfo.reguserstatus,
              } as UserInfoType
            }
          />
        ) : (
          <MySphLoginButton
            buttonStyle="hover:text-grey-800 text-[12px] text-grey-800"
            iconWidth="15.8px"
            iconHeight="15px"
          />
        )}
      </div>

      <MobileMenuModal expandMenu={expandMenu} onClick={toggleMobileMenu} />
    </>
  );
}
