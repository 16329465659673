import Link from "@elements/Typography/Link";
import { findStyleBySection } from "@src/app/helper/utils";
import { ReactNode } from "react";

import { SectionTitle } from "../Elements/Typography/Title";

export type SectionWrapperProps = {
  className?: string;
  sectionName: string;
  children?: ReactNode;
};

export default function HomeSectionBlock({
  className,
  sectionName,
  children,
}: SectionWrapperProps) {
  const { image } = findStyleBySection(sectionName) || {};
  return (
    <div
      className={
        className ??
        "w-full lg:w-1/2 items-center justify-between mt-lg md:mt-sm"
      }
    >
      <div className="flex items-center justify-between pb-xs w-full">
        <div className="flex items-center gap-2">
          <img
            src={image}
            alt={`${sectionName}-menu-icon`}
            className="h-md w-md"
          />
          <SectionTitle sectionName={sectionName} className="mb-0" />
        </div>

        <Link
          className="hover:underline"
          destination={sectionName}
        >{`Laporan Lain >`}</Link>
      </div>
      {children}
    </div>
  );
}
