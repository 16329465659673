import { UGCLandingPageQuery } from "@caas/UgcPageQueries";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import fetchCass from "@src/app/helper/fetchCaas";
import getOSProcessedArticleData, {
  ProcessedArticleData,
} from "@transformer/useOSResponse";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type { OSResponse, PartialCueArticle } from "@typings/Opensearch";

export type UGCLandingPayloadResponseType = {
  regular: ProcessedArticleData[];
  monthly: ProcessedArticleData[];
};

export const fetchUGCLandingPageData = async (): Promise<
  TRouteWithoutRedirect<UGCLandingPayloadResponseType, string>
> => {
  try {
    const resRegular = (await fetchCass({
      searchQuery: UGCLandingPageQuery({
        size: 2,
        campaignDisplay: "ARTICLES",
        campaignType: "REGULAR",
      }),
    })) as OSResponse<PartialCueArticle>;

    const resMonthly = (await fetchCass({
      searchQuery: UGCLandingPageQuery({}),
    })) as OSResponse<PartialCueArticle>;

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        regular: getOSProcessedArticleData(resRegular),
        monthly: getOSProcessedArticleData(resMonthly),
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchUGCLandingPageMoreData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchBHApi(
    "more-ugc-thematic-campaigns",
    "POST",
    {
      sort: requestData.sort,
    },
  );

  return response;
}
