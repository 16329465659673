import { GADataProps } from "@elements/GAData/GAData";
import {
  getAuthorsName,
  getContentCat,
  getTagsName,
} from "@elements/GAData/GADataHelper";
import { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { getArticleJSONLD } from "@helper/jsonLDHelper";
import { replaceSpacesWithUnderscores } from "@helper/stringTransform";
import { dateFormatWithTz, formatDate } from "@helper/useDateFormat";
import { getFirstParagraph } from "@pages/Article/ArticleMetaData";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { RawImageField } from "@typings/Opensearch";
import { PageMetaDataValues } from "@typings/PageMetaData";

import { MATA_MATA_SECTION } from "../UGCLandingPage/UGCLandingPage";

type UGCArticleMetaDataProps = {
  article: ProcessedArticleData;
  isMainArticle: boolean;
  gsChannels: string[];
};

export default function UGCArticleMetaData({
  article,
  isMainArticle,
  gsChannels,
}: UGCArticleMetaDataProps): PageMetaDataValues {
  const keywords = getTagsName(article.tags || []);
  const image = {
    url: article.imageField?.url,
    width: 480,
    height: 270,
  } as RawImageField;

  const gaData = {
    title: replaceSpacesWithUnderscores(article.title),
    level2: MATA_MATA_SECTION,
    chapter1: article.campaignReference?.title,
    contentcat: getContentCat(article.contentAccess),
    section: MATA_MATA_SECTION,
    contenttype: "1",
    pagination: 1,
    author: getAuthorsName(article.authors || []),
    cue_articleid: article.id,
    articleid: article.id,
    keyword: keywords,
    pubdate: formatDate(article.datetime, "yyyy-MM-dd HH:mm:ss"),
    content_publication_date_utc: article.datetime,
    content_update_date_utc: article.dateEdited,
    infinite_scroll: isMainArticle ? "0" : "1",
  } as GADataProps;

  const metaTags = {
    title: `${article.title} Berita - BeritaHarian.sg`,
    description: `Berita - ${getFirstParagraph(article.articleBody)} Baca lebih lanjut di BeritaHarian.sg`,
    slug: article.urlPath,
    ogType: "Article",
    keywords: keywords.split(","),
    robotsContent:
      "index, follow, notranslate, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
    twitterCardContent: "summary",
    image: image,
    isArticlePage: true,
    publishedTime: formatDate(article.datetime, dateFormatWithTz),
    modifiedTime: formatDate(article.dateEdited, dateFormatWithTz),
    jsonLd: getArticleJSONLD(article),
    gsChannels,
  } as MetaTagsProps;

  return {
    gaData: gaData,
    metaTags: metaTags,
  };
}
