import useScript from "@hooks/useScript/useScript";

import WrapperContainer from "../ParagraphElements/WrapperContainer";
import { TWITTER_SDK } from "./constants";

export type XEmbedCustomProps = {
  url: string;
  className?: string;
};

export default function XEmbedCustom({
  url,
  className = "",
}: XEmbedCustomProps): React.ReactElement {
  useScript({ id: "twitter-embed-script", src: TWITTER_SDK, async: true });

  return (
    <WrapperContainer className={className}>
      <blockquote className="twitter-tweet">
        <a href={url}>{url}</a>
      </blockquote>
    </WrapperContainer>
  );
}
