import { AdUnitProps } from "@src/app/types/Ads.d";
import cx from "classnames";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

import { AdUnit } from "./AdUnit";
import styles from "./Advertisement.module.css";
import { useAdvertLoaded } from "./helpers";

export type AdvertisementProps = {
  rootClassName?: string;
  adUnitProps: AdUnitProps;
};

// For now but must be move to tailwind custom class later rather than inline style like this.
export const stripeAdsBackground = {
  backgroundImage:
    "linear-gradient(135deg, #f0f0f0 8.33%, #ffffff 8.33%, #ffffff 50%, #f0f0f0 50%, #f0f0f0 58.33%, #ffffff 58.33%, #ffffff 100%)",
  backgroundSize: "8.49px 8.49px",
};

/**
 * This component renders an ad.
 * @param rootClassName - The root class name.
 * @param type - The type of the ad.
 * @param adUnitProps - The props for the ad.
 * @see https://www.imsph.sg/advertising-solutions/digital/imu/
 * @returns
 */
export default function Advertisement({
  rootClassName = "",
  adUnitProps,
}: AdvertisementProps): ReactElement {
  const advertLoaded = useAdvertLoaded(adUnitProps.type, adUnitProps.id);

  return (
    <div
      className={cx(
        !advertLoaded && styles.invisible,
        twMerge("py-sm", rootClassName),
      )}
      style={{
        minHeight: "50px",
      }}
      data-testid="advertisement-element"
    >
      <AdUnit {...adUnitProps} />
    </div>
  );
}
