import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import ThumbnailWrapper from "@src/app/components/Container/ThumbnailWrapper";
import PodcastDialog from "@src/app/components/Elements/Podcast/PodcastDialog";
import useSwrApi from "@src/app/hooks/useSwrApi";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { useState } from "react";

export enum PodcastBHCustomEventCategory {
  ARTICLE_PAGE = "article page podcast widget",
  PODCAST_PAGE = "podcast",
  HOME_PAGE = "podcast widget",
}

export type BHCustomEventPodcastProps = {
  eventCategory: PodcastBHCustomEventCategory;
  eventAction: "click" | "play";
  eventLabel?: string;
};

export type PodcastGalleryProps = {
  bhCustomEventProps: BHCustomEventPodcastProps;
};

export default function PodcastGallery({
  bhCustomEventProps,
}: PodcastGalleryProps) {
  const [podcastDialog, setPodcastDialog] = useState({
    visible: false,
    source: "",
  });
  const { data: podcasts } = useSwrApi<PodcastProcessedResponse[]>("podcasts");
  const bhCustomEvents = useBHCustomEventAnalyticsCall();

  const openPodcastDialog = (source?: string) => {
    setPodcastDialog({
      visible: true,
      source: source || "",
    });
    bhCustomEvents(
      bhCustomEventProps.eventCategory,
      bhCustomEventProps.eventAction,
      bhCustomEventProps.eventLabel,
    );
  };

  const closePodcastDialog = (source?: string) => {
    setPodcastDialog({
      visible: false,
      source: source || "",
    });
  };

  return (
    <>
      {podcasts && (
        <>
          <ThumbnailWrapper<PodcastProcessedResponse>
            variant="podcasts"
            renderedThumbnail={podcasts.slice(0, 6)}
            handleOnClickCallback={openPodcastDialog}
            thumbnailWrapperStyle="lg:w-1/6"
          />
          <PodcastDialog
            podcastDialog={podcastDialog}
            handleOnClickCloseDialog={closePodcastDialog}
          />
        </>
      )}
    </>
  );
}
