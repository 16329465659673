import {
  SECONDARY_SECTION_LIST,
  SectionDetails,
} from "@components/HeaderNew/headerMenuConstants";
import { HeaderMenuList } from "@components/HeaderNew/HeaderMenuList";
import { getFeatureFlag } from "@helper/utils";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import { type ReactElement, useEffect, useState } from "react";

export type SecondaryLinksProps = Readonly<{
  testId?: string;
}>;

export function SecondaryLinks({
  testId = "desktop-secondary-links",
}: SecondaryLinksProps): ReactElement {
  const [secondarySectionList, setSecondarySectionList] = useState<
    SectionDetails[]
  >([]);

  useEffect(() => {
    const filter_header = async () => {
      const webinar: string = await getFeatureFlag(FeatureFlags.WEBINAR);
      setSecondarySectionList(
        SECONDARY_SECTION_LIST.filter((item) => {
          if (item.title === "Webinar" && webinar === "1") {
            return true;
          }
          return item.title !== "Webinar";
        }),
      );
    };
    void filter_header();
  }, []);

  return (
    <div
      data-testid={`${testId}-secondaryLinks`}
      className="hidden lg:w-[840px] xl:w-[915px] lg:flex justify-end"
    >
      <div data-testid={`${testId}-secondaryLinksInner`}>
        <HeaderMenuList
          testId={testId}
          items={secondarySectionList}
          type="secTopics"
        />
      </div>
    </div>
  );
}
