import { BrowserLoggerLive, BrowserLoggerLocal } from "@sphtech/web2-logger";
import { MODE } from "@src/app/helper/getEnvVariables";

// TODO: typing is messed up here, this should be fixed when we move
// isLiveEnvironment is moved to web2-core
export enum DeploymentEnvironment {
  DEV = "development",
  STAGING = "staging",
  PROD = "production",
  LOCAL = "local",
  NONE = "none",
}

type TLogger = typeof BrowserLoggerLive | typeof BrowserLoggerLocal;

function getLogger(): TLogger {
  if (
    MODE === (DeploymentEnvironment.PROD as string) ||
    MODE === (DeploymentEnvironment.STAGING as string) ||
    MODE === (DeploymentEnvironment.DEV as string)
  ) {
    return BrowserLoggerLive;
  } else {
    return BrowserLoggerLocal;
  }
}

const Logger = getLogger();

export { Logger };
