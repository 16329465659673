import { DividerDot } from "@src/app/components/Elements/Divider/Divider";
import { twMerge } from "tailwind-merge";

import Heading from "../Elements/Typography/Heading";
import Link from "../Elements/Typography/Link";
import Thumbnail from "./Thumbnail";

interface ThumbnailItem {
  published_at?: string;
  name?: string;
  thumbnail?: string;
  src?: string;
  id?: string;
}
export type ThumbnailWrapperProps<T> = {
  variant: "podcasts" | "videos";
  renderedThumbnail?: (T & ThumbnailItem)[];
  handleOnClickCallback: (source?: string) => void;
  thumbnailWrapperStyle?: string;
};

export default function ThumbnailWrapper<T>({
  variant,
  renderedThumbnail,
  handleOnClickCallback,
  thumbnailWrapperStyle,
}: ThumbnailWrapperProps<T>) {
  return (
    <div className="flex flex-col gap-4 w-full shadow-slider border border-grey-100 border-t-0 py-4 my-4 px-md lg:px-lg">
      <Heading className="capitalize self-center text-md font-semimedium font-primary">{`${variant} Baru`}</Heading>
      <DividerDot />

      <div className="flex gap-4 overflow-x-scroll lg:overflow-auto">
        {renderedThumbnail &&
          renderedThumbnail.map((item, index) => (
            <button
              className={twMerge("h-full lg:max-w-max", thumbnailWrapperStyle)}
              key={index}
              onClick={() => {
                handleOnClickCallback(item.src ? item.src : item.id);
              }}
            >
              <div className="flex flex-col items-start gap-y-1 text-start min-w-[170px] lg:min-w-full lg:max-w-max lg:w-1/6">
                <Thumbnail src={item.thumbnail} />
                {item.published_at && (
                  <span className="text-xs text-blue-400">
                    {item.published_at}
                  </span>
                )}
                {item.name && (
                  <Heading className="text-xs font-semimedium">
                    {item.name}
                  </Heading>
                )}
              </div>
            </button>
          ))}
      </div>

      <Link
        destination={variant}
        className="capitalize text-md self-center text-blue-300 hover:underline"
      >{`+ ${variant} lain`}</Link>
    </div>
  );
}
