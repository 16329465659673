import Audio from "../../Audio/Audio";

export type PodcastDialogProps = {
  podcastDialog: {
    visible: boolean;
    source: string;
  };
  handleOnClickCloseDialog: () => void;
};

export default function PodcastDialog({
  podcastDialog,
  handleOnClickCloseDialog,
}: PodcastDialogProps) {
  const { visible, source } = podcastDialog;
  return (
    <>
      {visible && (
        <div className="fixed bottom-4 right-2 z-50 p-4 h-[200px]">
          <div className="relative">
            <Audio src={source} />
          </div>
          <div className="z-10 absolute top-2 right-1 flex items-center justify-center w-8 h-8 p-1 rounded-full bg-black-500">
            <button
              onClick={handleOnClickCloseDialog}
              id="close"
              className="text-white-100 text-sm outline-none focus:outline-none"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
