import distanceIcon from "@assets/foodmap/distance.svg";
import locationIcon from "@assets/foodmap/location.svg";
import phoneIcon from "@assets/foodmap/phone.svg";
import worldIcon from "@assets/foodmap/world.svg";
import Link from "@elements/Typography/Link";
import decodedHtml from "@src/app/helper/decodedHtml";
import { RestaurantDataWithDistance } from "@src/app/types/foodMapTypes";
import DOMPurify from "dompurify";
import { useState } from "react";

type FoodMapRestaurantOverlayProps = {
  restaurant: RestaurantDataWithDistance;
  handleCloseOverlay: (e: boolean) => void;
};

export default function FoodMapRestaurantOverlay({
  restaurant,
  handleCloseOverlay,
}: FoodMapRestaurantOverlayProps) {
  const [closeOverlay, setCloseOverlay] = useState<boolean>(false);

  const handleClose = () => {
    setCloseOverlay(true);
    handleCloseOverlay(true);
  };

  const decodedInformation = restaurant.information
    ? decodedHtml(restaurant.information)
    : "";
  const sanitizedInformation = DOMPurify.sanitize(decodedInformation);

  return (
    <>
      {!closeOverlay ? (
        <div className="flex flex-col gap-2 px-sm">
          <div className="px-sm pb-xs border-b-[1px] border-black-100">
            <div className="flex">
              <div className="basis-11/12 text-xl font-secondary text-black-300 pt-[8px]">
                {restaurant.name}
              </div>
              <div className="basis-1/12 text-right">
                <button
                  className="text-[28px] font-medium text-grey-400"
                  onClick={handleClose}
                >
                  x
                </button>
              </div>
            </div>
            {restaurant.site && (
              <div>
                <Link
                  className="w-[105px] flex items-center gap-1 bg-blue-300 px-[12px] py-[8px] rounded-[4px] text-sm"
                  destination={restaurant.site}
                  relativePath={false}
                >
                  <img
                    src={worldIcon}
                    width={14}
                    height={22}
                    alt="world-icon"
                  />
                  <span className="text-white-100">Website</span>
                </Link>
              </div>
            )}
          </div>
          <div className="px-sm">
            <div className="flex py-[8px] gap-1 items-center text-xs text-black-300">
              <img src={distanceIcon} alt="distance-icon" />
              <div>
                About{" "}
                <span className="font-semibold">
                  {(restaurant.distance * 1000).toFixed(0)}
                </span>{" "}
                Meters away
              </div>
            </div>
            {restaurant.vicinity && (
              <div className="py-[8px]">
                <img
                  className="inline mr-[2px] align-text-top"
                  width={15}
                  height={18}
                  src={locationIcon}
                  alt="location-icon"
                />
                <span className="text-grey-600 text-md">
                  {restaurant.vicinity}
                </span>
              </div>
            )}
            {restaurant.number && (
              <div className="flex py-[8px] gap-1 items-center">
                <img width={19} height={18} src={phoneIcon} alt="phone-icon" />
                <Link
                  className="text-blue-300 text-md"
                  destination={`tel:${restaurant.number}`}
                  relativePath={true}
                  target="_blank"
                >
                  {restaurant.number}
                </Link>
              </div>
            )}
            {sanitizedInformation && (
              <div
                className="mt-sm flex flex-col gap-3 text-xxs text-black-300 h-[22vh] overflow-y-scroll"
                dangerouslySetInnerHTML={{ __html: sanitizedInformation }}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
