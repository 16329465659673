import getFallbackSectionImage from "@src/app/helper/getFallbackSectionImage";
import { twMerge } from "tailwind-merge";

import { Image } from "../Elements/ResponsiveImage/Image";
import ArticleCarouselFooter from "./ArticleCarouselFooter";

type ArticleImageProps = {
  url?: string;
  width?: number;
  height?: number;
  sectionName?: string;
  caption: string;
  credit: string;
  alt: string;
  index?: string;
  className?: string;
};

export default function ArticleImage({
  alt,
  url,
  width = 825,
  height = 550,
  sectionName,
  caption,
  credit,
  index,
  className,
}: ArticleImageProps) {
  const dummyImage = getFallbackSectionImage(sectionName);
  return (
    <div className={twMerge("max-w-[825px] mb-4", className)} key={index}>
      <div className="w-full">
        <Image
          src={url ?? dummyImage}
          displayWidth={width}
          srcWidth={width}
          srcHeight={height}
          alt={alt}
        />
        <ArticleCarouselFooter caption={caption} credit={credit} />
      </div>
    </div>
  );
}
