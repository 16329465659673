import { SectionDetails } from "@components/HeaderNew/headerMenuConstants";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import List from "../Elements/List/List";
import { ListItem } from "../Elements/List/ListItem/ListItem";
import { HeaderMenuType } from "./headerMenuTypes";

type HeaderMenuListProps = {
  testId: string;
  items: SectionDetails[];
  type: HeaderMenuType;
  addActiveColor?: boolean;
};

export function HeaderMenuList({
  testId,
  items,
  type,
  addActiveColor = false,
}: HeaderMenuListProps) {
  const { pathname } = useLocation();

  const isActive = (location: string): boolean => {
    const pathnameOnly = location.split("?")[0];
    const sectionName = pathname.split("/")[1];
    return pathname === pathnameOnly || `/${sectionName}` === pathnameOnly;
  };

  return (
    <List
      testId={`${testId}-${type}-menuList`}
      style={cx(
        type === "topics" &&
          "flex items-center justify-center gap-sm lg:gap-2md xl:gap-xl",
        type === "secTopics" &&
          "flex items-center justify-end lg:gap-lg xl:gap-xl",
        type === "misc" && "flex items-center justify-center",
      )}
    >
      {items.map((item: SectionDetails, index: number) => (
        <ListItem
          key={`${testId}-item-${index}`}
          testId={`${testId}-item-${index}`}
          className={cx(
            "cursor-pointer",
            type === "topics" &&
              "flex items-center justify-center text-sm lg:text-xs font-semimedium lg:font-regular lg:leading-[35px] gap-xxs whitespace-nowrap transition-transform duration-300 ease-out hover:scale-x-110",
            type === "secTopics" &&
              "flex items-center text-xs font-regular leading-[35px] transition-transform duration-300 ease-out hover:scale-x-110",
            type === "misc" && "text-xxs text-grey-600 ",
            type === "mobileMain" &&
              "flex h-[54px] items-center [&_a]:text-white-500 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
            type === "mobileSection" &&
              "flex h-[54px] items-center [&_a]:w-full [&_a]:text-sm [&_a]:font-semimedium",
            type === "mobileMisc" &&
              "flex h-[54px] [&_a]:text-white-500 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
            type === "mobileSecondary" &&
              "flex h-[54px] [&_a]:text-gold-200 [&_a]:text-sm [&_a]:font-semimedium [&_a]:w-full",
          )}
        >
          <div
            className={`flex items-center gap-xxs min-w-max ${
              addActiveColor &&
              isActive(item.url) &&
              type === "secTopics" &&
              `border-b-[3px] border-${item.selectedColor}`
            } ${type === "misc" && "gap-0"}`}
          >
            <Link
              reloadDocument
              key={index}
              to={item.url}
              className={cx(
                type === "misc" && "font-regular text-blue-400",
                type === "topics"
                  ? addActiveColor && !isActive(item.url)
                    ? item.neutralColor
                      ? "text-grey-800"
                      : "text-red-300"
                    : "text-black-700 lg:text-white-500"
                  : "",
                type === "secTopics"
                  ? addActiveColor
                    ? isActive(item.url)
                      ? `text-${item.selectedColor}`
                      : "text-grey-800"
                    : "text-white-500"
                  : "",
                type === "mobileSection" ? "text-white-500" : "",
              )}
            >
              {item.title}
            </Link>
            {type === "misc" && (
              <span className="px-xxs text-grey-150 text-sm font-regular">
                |
              </span>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
}
