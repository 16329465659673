import moreCollapsedImg from "@assets/header/more-collapsed.svg";
import moreExpandedImg from "@assets/header/more-expanded.svg";
import {
  SECTION_LIST,
  SectionDetails,
} from "@components/HeaderNew/headerMenuConstants";
import { FeatureFlags } from "@src/app/featureFlag/featureFlags";
import { getFeatureFlag } from "@src/app/helper/utils";
import { type ReactElement, useEffect, useState } from "react";

import { HeaderMenuList } from "./HeaderMenuList";
import { SearchButton } from "./Search/SearchButton";
import { SearchInput } from "./Search/SearchInput";
import { SecondaryLinks } from "./SecondaryLinks";

export type SectionQuickLinksProps = Readonly<{
  testId?: string;
}>;

enum Buttons {
  MORE_BUTTON = "more",
  SEARCH_BUTTON = "search",
}

export function SectionQuickLinks({
  testId = "desktop-quick-links",
}: SectionQuickLinksProps): ReactElement {
  const [sectionList, setSectionList] = useState<SectionDetails[]>([]);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [moreMenuExpanded, setMoreMenuExpanded] = useState(false);
  const toggleExpanded = (type: Buttons) => {
    if (type === Buttons.MORE_BUTTON) {
      setMoreMenuExpanded((prev) => !prev);
      setSearchExpanded(false);
    } else {
      setMoreMenuExpanded(false);
      setSearchExpanded((prev) => !prev);
    }
  };

  useEffect(() => {
    const filter_header = async () => {
      const genG_Flags: string = await getFeatureFlag(FeatureFlags.GEN_G);

      setSectionList(
        SECTION_LIST.filter((item) => {
          if (item.title === "Gen G" && genG_Flags === "1") {
            return true;
          }
          return item.title !== "Gen G";
        }),
      );
    };
    void filter_header();
  }, []);

  return (
    <>
      <div
        data-testid={`${testId}-quickLinks`}
        className="flex justify-center text-sm font-semimedium bg-white-100 lg:bg-gradient-to-r from-blue-400 to-blue-450"
      >
        <div
          data-testid={`${testId}-quickLinksInner`}
          className="flex w-full lg:max-w-screen-lg xl:max-w-screen-xl"
        >
          <div
            data-testid={`${testId}-topicsListWrapper`}
            className="h-full flex w-full justify-between border-b-white-200 border-b-[1px] lg:border-b-0"
          >
            <div className="flex h-[40px] lg:h-auto lg:gap-xs xl:gap-md px-xs xl:px-sm overflow-x-scroll lg:overflow-visible">
              <HeaderMenuList
                testId={testId}
                items={sectionList}
                type="topics"
              />
              <div
                className={`hidden lg:flex items-center lg:px-xs xl:px-sm ${moreMenuExpanded && "bg-blue-100"}`}
              >
                <button
                  className="flex items-center gap-1 text-xs lg:text-white-500"
                  onClick={() => {
                    toggleExpanded(Buttons.MORE_BUTTON);
                  }}
                >
                  More
                  <img
                    src={moreMenuExpanded ? moreExpandedImg : moreCollapsedImg}
                    alt="more-menu-icon"
                    width={12}
                    height={12}
                  />
                </button>
              </div>
            </div>
            <div
              data-testid={`${testId}-headerSearch`}
              className="hidden lg:block"
            >
              <SearchButton
                expanded={searchExpanded}
                onClick={() => {
                  toggleExpanded(Buttons.SEARCH_BUTTON);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        data-testid={`${testId}-secondaryNav`}
        className={`hidden lg:flex justify-center bg-gradient-to-r from-blue-400 to-blue-100 transition-all duration-500 ease-in-out
            ${moreMenuExpanded ? "opacity-100 h-[35px] visible" : "opacity-0 h-0 invisible"}
          `}
      >
        <SecondaryLinks testId={testId} />
      </div>
      <div
        data-testid={`${testId}-searchInputDesktop`}
        className={`hidden lg:flex justify-center bg-grey-1000 transition-all duration-500 ease-in-out
            ${searchExpanded ? "opacity-100 h-[35px] visible" : "opacity-0 h-0 invisible"}
          `}
      >
        <SearchInput expanded={searchExpanded} />
      </div>
    </>
  );
}
